const selectTheme = () => {
  const selectThemeBtn = document.querySelector(".js-select-theme-btn");
  const selectThemeDropdown = document.querySelector(".select-theme-dropdown");
  const selectThemeItems = document.querySelectorAll(
    ".select-theme-dropdown__item"
  );

  if (selectThemeBtn) {
    selectThemeBtn.addEventListener("click", () => {
      if (
        selectThemeDropdown.classList.contains("select-theme-dropdown--open")
      ) {
        selectThemeDropdown.classList.remove("select-theme-dropdown--open");
        selectThemeBtn.classList.remove("select-theme__btn--open");
      } else {
        selectThemeDropdown.classList.add("select-theme-dropdown--open");
        selectThemeBtn.classList.add("select-theme__btn--open");
      }
    });
  }

  if (selectThemeItems) {
    selectThemeItems.forEach((item) => {
      item.addEventListener("click", () => {
        const [, text] = item.children;
        selectThemeBtn.children[0].innerHTML = text.innerHTML;
        selectThemeDropdown.classList.remove("select-theme-dropdown--open");
        selectThemeBtn.classList.remove("select-theme__btn--open");
      });
    });
  }
};

export default selectTheme;
