const customSelect = () => {
  const feedbackForm = document.querySelector('.js-form');
  const select = document.querySelector('.js-select-btn');

  if (select) {
    const phoneEmailSwitch = feedbackForm.querySelector('.select__switcher');
    const phoneVariantBtn = feedbackForm.querySelector('.js-phone-variant-btn');
    const emailVariantBtn = feedbackForm.querySelector('.js-email-variant-btn');
    const phoneVariantInput = feedbackForm.querySelector('.js-input-phone');
    const emailVariantInput = feedbackForm.querySelector('.js-input-email')

    select.addEventListener('click', () => {
      select.classList.toggle('is-active');
      phoneEmailSwitch.classList.toggle('is-active');
    })

    phoneVariantBtn.addEventListener('click', () => {
      phoneEmailSwitch.classList.remove('is-active');
      select.classList.remove('select__block--email');
      select.classList.remove('is-active');
      emailVariantInput.removeAttribute('required')
      phoneVariantInput.removeAttribute('disabled');
      emailVariantInput.setAttribute('disabled', true);
      phoneVariantInput.setAttribute('required', true)
    })

    emailVariantBtn.addEventListener('click', () => {
      select.classList.add('select__block--email');
      select.classList.remove('is-active');
      phoneEmailSwitch.classList.remove('is-active');
      phoneVariantInput.removeAttribute('required')
      emailVariantInput.removeAttribute('disabled');
      phoneVariantInput.setAttribute('disabled', true);
      emailVariantInput.setAttribute('required', true)
    })
  }
}

export default customSelect
